export const bibleVerses = {
  1: "데살로니가전서 5:16-18",
  2: "로마서 12:12",
  3: "골로새서 4:2",
  4: "야고보서 1:5",
  5: "마가복음 9:29",
  6: "마태복음 18:19",
  7: "예레미야 29:12",
  8: "에베소서 3:20",
  9: "시편 50:15",
  10: "시편 34:17",
  11: "마태복음 6:33",
  12: "시편 37:23-24",
  13: "이사야 40:31",
  14: "요한복음 13:34",
  15: "요한복음 16:24",
  16: "빌립보서 4:6",
  17: "시편 91:15",
  18: "이사야 41:10",
  19: "야고보서 5:16",
  20: "요한복음 15:7",
  21: "히브리서 4:14-16",
  22: "야고보서 4:8",
  23: "시편 86:5",
  24: "시편 62:8",
  25: "예레미야 33:3",
  26: "시편 55:22",
  27: "데살로니가후서 3:3",
  28: "시편 145:18",
  29: "시편 37:4",
  30: "사무엘상 1:27",
  31: "시편 138:3",
};
